import React,{useState ,useReducer, useEffect} from "react";
import { Link ,useHistory} from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Image
import profile from "../../../images/user.jpg";
import avatar from "../../../images/logomob.png";
import LogoutPage from './Logout';
import PageTitle from "../../layouts/PageTitle";
import { Row, Card, Col, Button, Modal, Container, Dropdown ,Badge } from "react-bootstrap";
import swal from "sweetalert";
import axios from "axios";

import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';

const Header = ({ onNote }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const logoutSubmit = (e) => {
        e.preventDefault();
        axios.post(`/logout`).then(res => {
        	console.log(res.data.message)
            if(res.data.message === 'Unauthenticated')
            {
                localStorage.removeItem('auth_token');
                swal("Success",res.data.message,"success");
                history.push('/login');
            }
        });
    }
      axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
        if(err.response.status === 401)
        {
        	localStorage.removeItem('auth_token');
            swal("Unauthorized",err.response.data.message,"warning");
            history.push('/login');
        }
        return Promise.reject(err);
    });

    const [loginInput, setLogin] = useState({
        username: '',
        password: '',
        error_list: [],
    });

    const handleInput = (e) => {
        e.persist();
        setLogin({...loginInput, [e.target.name]: e.target.value });
    }

    const loginSubmit = (e) => {
        e.preventDefault();
        
        const data = {
            username: loginInput.username,
            password: loginInput.password,
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
        }
            axios.post(`/apps-user-login`, data).then(res => {
                if(res.data.status === 'success')
                {
                    localStorage.setItem('auth_token', res.data.accessToken);
                    swal("Success",res.data.message,"success");
                    history.push('/');

                }
                else if(res.data.status === 'fail')
                {

                    swal("Warning",res.data.message,"warning");
                }
                else 
                {
                setLogin({...loginInput, error_list: res.data.validation_error});
                }
            });
          }

var AuthButtons = '';
if(!localStorage.getItem('auth_token'))
{
  AuthButtons = (
	<ul className="navbar-nav header-right">
	    <div as="li" className="nav-item">
	        <Button variant="outline-primary btn-square btn-xxs" onClick={handleShow}>
	            <strong>Login</strong>
	        </Button>
	    </div>
	    <Modal show={show} onHide={handleClose}>
	        <Modal.Header closeButton>
	            <Modal.Title>Login Here</Modal.Title>
	        </Modal.Header>
	         <Modal.Body>
	            <form onSubmit={loginSubmit}>
		            <div className="form-group mb-3">
		               <div className="form-group">
		                  <div className="form-group mb-3">
		                       <label className="mb-1 ">
		                          <strong>Username</strong>
		                        </label>
		                    <input
		                      type="text"
		                      className="form-control"
		                      placeholder="username"
		                       name="username"
		                     onChange={handleInput} 
		                     value={loginInput.username}
		                      />
		                   </div>
		                  <span>{loginInput.error_list.username}</span>
		                 

                        <label className="text-label">Password</label>
                        <div className="input-group transparent-append mb-2">
                          
                            <span className="input-group-text">
                              {" "}
                              <i className="fa fa-lock" />{" "}
                            </span>
                          
                          <input
                            type={`${showPassword ? "text" : "password"}`}
                            className="form-control"
                            id="val-password1"
                            placeholder="password"
                            name="password"
                            onChange={handleInput} 
                            value={loginInput.password}
                          />

                          <div
                            className="input-group-text "
                            onClick={() => setShowPassword(!showPassword)}
                          >

                              {" "}
								  {showPassword === false ? (<i className="fa fa-eye-slash" />) : (<i className="fa fa-eye" />)}
                            
                          </div>
                          <div
                            id="val-username1-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                          </div>


                        </div>
		                 <span>{loginInput.error_list.password}</span>



		                  <div className="text-center mt-4">
		                      <button type="submit" className="btn btn-primary btn-block">
		                        Login
		                      </button>
		                  </div>
		              </div>
		            </div>
	        	</form>
	        	<div className="new-account mt-3">
					<p>Don't have an account? <Link className="text-primary" to="./register">Sign up</Link>
					</p>
				</div>
	        </Modal.Body>
	    </Modal>
	    <Dropdown as="li" className="nav-item">
		    <Link to="/register" >
				<Button variant="outline-primary btn-square btn-xxs" >
				    <strong>signup</strong>
				</Button>
			</Link>
		</Dropdown>
	</ul>
   );
    }
    else
    {
  AuthButtons = (
	<ul className="navbar-nav header-right">
		<Dropdown as="li" className="nav-item dropdown header-profile">
	      <Dropdown.Toggle variant="" as="a" className="nav-link i-false c-pointer">
	       <Link to={"/menu"}>
	        <img src={profile} width={30} alt="Profile" />
	        </Link>
	      </Dropdown.Toggle>
		</Dropdown>
	</ul>
        );
    }

const [searchBut, setSearchBut] = useState(false);	
  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("-");
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  var finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName.includes("ui")
    ? filterName.filter((f) => f !== "ui")
    : filterName.includes("uc")
    ? filterName.filter((f) => f !== "uc")
    : filterName.includes("basic")
    ? filterName.filter((f) => f !== "basic")
    : filterName.includes("jquery")
    ? filterName.filter((f) => f !== "jquery")
    : filterName.includes("table")
    ? filterName.filter((f) => f !== "table")
    : filterName.includes("page")
    ? filterName.filter((f) => f !== "page")
    : filterName.includes("email")
    ? filterName.filter((f) => f !== "email")
    : filterName.includes("ecom")
    ? filterName.filter((f) => f !== "ecom")
    : filterName.includes("chart")
    ? filterName.filter((f) => f !== "chart")
    : filterName.includes("editor")
    ? filterName.filter((f) => f !== "editor")
    : filterName;
  return ( 
    <div className="header border-bottom">
        <div className="header-content">
		    <nav className="navbar navbar-expand">
		      <div className="collapse navbar-collapse justify-content-between">
		        <div className="header-left">
					<div
						className="dashboard_bar"
						style={{ textTransform: "capitalize" }}
					  >
					</div>
		       </div>

			<div className="nav-item d-flex align-items-center">
				<div className="input-group search-area">
					<input type="text" 
						className={`form-control ${searchBut ? "active" : ""}`}
						placeholder="Search.." 
					/>
					<span className="input-group-text" onClick={() => setSearchBut(!searchBut)}>
						<Link to={"#"}><i className="flaticon-381-search-2"></i></Link>
					</span>
				</div>
			</div> 
		        <ul className="navbar-nav header-right">
		            <Dropdown as="li" className="nav-item dropdown notification_dropdown">
						<Dropdown.Toggle className="nav-link i-false c-pointer" variant="" as="a">
							<svg xmlns="http://www.w3.org/2000/svg" width="19.375" height="24" viewBox="0 0 19.375 24">
								<g id="_006-notification" data-name="006-notification" transform="translate(-341.252 -61.547)">
									<path id="Path_1954" data-name="Path 1954" d="M349.741,65.233V62.747a1.2,1.2,0,1,1,2.4,0v2.486a8.4,8.4,0,0,1,7.2,8.314v4.517l.971,1.942a3,3,0,0,1-2.683,4.342h-5.488a1.2,1.2,0,1,1-2.4,0h-5.488a3,3,0,0,1-2.683-4.342l.971-1.942V73.547a8.4,8.4,0,0,1,7.2-8.314Zm1.2,2.314a6,6,0,0,0-6,6v4.8a1.208,1.208,0,0,1-.127.536l-1.1,2.195a.6.6,0,0,0,.538.869h13.375a.6.6,0,0,0,.536-.869l-1.1-2.195a1.206,1.206,0,0,1-.126-.536v-4.8a6,6,0,0,0-6-6Z" transform="translate(0 0)" fill="#135846" fill-rule="evenodd"/>
							    </g>
							</svg>
							<span className="badge light text-white bg-primary rounded-circle">1</span>
						</Dropdown.Toggle>
						<Dropdown.Menu align="right" className="mt-2 dropdown-menu dropdown-menu-end">
							<PerfectScrollbar className="widget-media dlab-scroll p-3 height380">
								<ul className="timeline">
								  <li>
									<div className="timeline-panel">
										<div className="media me-2">
											<img alt="images" width={50} src={avatar} />
										</div>
										<div className="media-body">
											<h6 className="mb-1">Hello Dear, Welcome to BetFanda .</h6>
										</div>
									</div>
								  </li>
								</ul>
								<div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
								  <div
									className="ps__thumb-x"
									tabIndex={0}
									style={{ left: 0, width: 0 }}
								  />
								</div>
								<div className="ps__rail-y" style={{ top: 0, right: 0 }}>
								  <div
									className="ps__thumb-y"
									tabIndex={0}
									style={{ top: 0, height: 0 }}
								  />
								</div>
							</PerfectScrollbar>
							<Link className="all-notification" to="#">
								See all notifications <i className="ti-arrow-right" />
						    </Link>
						</Dropdown.Menu>
			        </Dropdown>
					 {AuthButtons}
			    </ul>
		      </div>
		    </nav>
        </div>
    </div>
  );
};

export default Header;
